import React, { useEffect } from "react";

const PBIRenderer = ({ reportURL }) => {
  return (
    <iframe
      width="910"
      height="665"
      src={reportURL}
      frameBorder="0"
      allowFullScreen
    ></iframe>
  );
};

export default PBIRenderer;
