import React, { useState } from "react";
import bs from "../assets/tg-logo.png";

const Header = () => {
  return (
    <div
      style={{ color: "#004C49", fontFamily: "MuseoModerno" }}
      className="d-flex align-items-center justify-content-center head clearfix"
    >
      <div style={{ marginBottom: 6 }}>
        <h1>NOVA</h1>
      </div>
      &nbsp;
      <div>
        <h6>x</h6>
      </div>
      &nbsp;
      <div style={{ marginTop: 7 }}>
        <img className="logo" src={bs} alt="logo" />
      </div>
    </div>
  );
};

export default Header;
